.overview {
  background-color: #ffffff;
  margin-bottom: 16px;
  .overview-data:nth-child(1) {
    border-top: none;
  }

  &-title {
    font-size: 16px;
    font-weight: 500;
    color: #262626;
    padding: 16px 16px 0 16px;

    button {
      svg {
        margin-right: 5px;
      }
      padding: 0 !important;
    }
  }

  &-data {
    color: #818181;
    font-size: 14px;
    border-top: 1px solid #d9d9d9;
    padding: 16px;

    &-info {
      color: #262626;
    }
  }

  &-box {
    padding: 16px;

    .card {
      height: 100%;
    }
  }

  button {
    font-size: 14px;
    line-height: 22px;
    height: auto;
    /* identical to box height, or 157% */

    display: flex;
    align-items: center;
    text-align: right;

    // color: #1890ff;

    padding: 16px 16px 0 0;
  }

  .ant-typography {
    margin-right: 16px;
    margin-top: 16px;
  }
}

@media screen and (max-width: 1366px) {
  .overview {
    .overview-box {
      flex-direction: column;
    }

    .ui-user-card:first-child {
      margin-bottom: 10px;
    }
  }
}
